import { useState, memo, useCallback } from 'react';
import { InView } from 'react-intersection-observer';
import Layout from '../components/Layout';
import MainSection from '../components/Sections/MainSection';
import HowItWorksSection from '../components/Sections/HowItWorksSection';
// import RaffleSection from '../components/Sections/RaffleSection';
import ContactSection from '../components/Sections/ContactSection';
import FaqSection from '../components/Sections/FaqSection';
import { SECTIONS_PATHS } from '../utils/data';
import SEO from '../components/SEO';

// markup
// eslint-disable-next-line no-unused-vars
const IndexPage = () => {
  // const Window60Height = (window.innerHeight * 40) / 100;
  const [sectionActive, setSectionActive] = useState('');

  // TODO:  Probleme of type
  const handleSection = useCallback(
    (type: any) => {
      setSectionActive(type);
    },
    [setSectionActive],
  );

  return (
    <>
      <SEO
        // title={`${data.site.siteMetadata.title} | vignette.sgmaroc.com`}
        keywords={[
          'paiement vignette maroc',
          'ma vignette',
          'payer en ligne vignette maroc',
          'paiement vignette maroc en ligne',
          'payer vignette en ligne',
          'vignette en ligne',
          'ma vignette maroc',
          'paiement en ligne maroc',
          'paiement internet maroc',
          'impot voiture maroc',
          'taxe voiture maroc',
          'payer taxe voiture maroc',
          'payer dariba maroc',
          'payer dariba en ligne',
          'payer dariba',
          'vignette 2022',
          'payer vignette 2022',
          'payer vignette 2022 maroc',
        ]}
      />
      <Layout isActive={sectionActive}>
        <InView
          as="div"
          threshold={0.5}
          initialInView
          onChange={inView => inView && handleSection(SECTIONS_PATHS.PAYMENT)}
        >
          <MainSection id={SECTIONS_PATHS.PAYMENT} />
        </InView>
        <InView
          as="div"
          threshold={0.75}
          onChange={inView =>
            inView && handleSection(SECTIONS_PATHS.HOW_IT_WORKS)
          }
        >
          <HowItWorksSection id={SECTIONS_PATHS.HOW_IT_WORKS} />
        </InView>
        {/*  <InView
          as="div"
          threshold={0.75}
          onChange={inView => inView && handleSection(SECTIONS_PATHS.RAFFLE)}
        >
          <RaffleSection id={SECTIONS_PATHS.RAFFLE} />
        </InView> */}
        <InView
          as="div"
          threshold={0.75}
          onChange={inView => inView && handleSection(SECTIONS_PATHS.FAQ)}
        >
          <FaqSection id={SECTIONS_PATHS.FAQ} />
        </InView>
        <InView
          as="div"
          threshold={0.75}
          onChange={inView => inView && handleSection(SECTIONS_PATHS.CONTACT)}
        >
          <ContactSection id={SECTIONS_PATHS.CONTACT} />
        </InView>
      </Layout>
    </>
  );
};

export default memo(IndexPage);
