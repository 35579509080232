import { memo, ReactNode } from 'react';
import styled from 'styled-components';

import { lighten } from 'polished';

interface CardItemProps {
  headImgSrc?: string;
  title?: ReactNode;
  description?: string;
}

const StyledCard = styled.div<{ props?: any }>`
  display: flex;
  flex-direction: column;
  width: 204px;
  box-shadow: 0 0 20px 0px ${props => props.theme.colors.shadow};
  padding: 45px 35px 65px 35px;
  background-color: ${props => props.theme.colors.mainBackground};
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 1024px) {
    max-width: none;
    width: 30%;
  }
  @media only screen and (max-width: 768px) {
    padding: 35px 20px 35px 20px;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:before {
    content: '';
    position: absolute;
    left: ${props => (props.theme.rtl ? '100%' : 0)};
    top: 0;
    width: 10px;
    height: 10px;
    opacity: 0;
    background-color: ${props => lighten('0', props.theme.colors.shadow)};
    border-radius: 100%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease, box-shadow 0.3s ease 0.05s;
    z-index: 0;
    box-shadow: 0 0 0 0 ${props => lighten('0.02', props.theme.colors.shadow)},
      0 0 0 0 ${props => lighten('0.04', props.theme.colors.shadow)},
      0 0 0 0 ${props => lighten('0.06', props.theme.colors.shadow)};
  }

  &:hover {
    &:before {
      opacity: 1;
      width: 300px;
      height: 300px;
      box-shadow: 0 0 0 100px
          ${props => lighten('0.02', props.theme.colors.shadow)},
        0 0 0 200px ${props => lighten('0.04', props.theme.colors.shadow)},
        0 0 0 400px ${props => lighten('0.06', props.theme.colors.shadow)};
    }
  }
`;

const StyledCardBody = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  .card-img {
    margin-bottom: 34px;
    min-height: 70px;
    @media only screen and (max-width: 640px) {
      min-height: auto;
    }
  }
  .card-title {
    font-family: ${props => props.theme.fonts.Montserrat};
    font-size: 12px;
    font-weight: ${props => props.theme.fontWeights.Montserrat.extraBold};
    line-height: 14px;
    text-transform: uppercase;
    margin-bottom: 14px;
  }
  p {
    font-family: ${props => props.theme.fonts.Montserrat};
    font-size: 12px;
    font-weight: ${props => props.theme.fontWeights.Montserrat.regular};
    line-height: 14px;
  }
`;

function CardItem({ headImgSrc, title = null, description }: CardItemProps) {
  return (
    <StyledCard>
      <StyledCardBody>
        {headImgSrc && (
          <div className="card-img">
            <img src={headImgSrc} alt="" />
          </div>
        )}
        {title}
        {description && <p>{description}</p>}
      </StyledCardBody>
    </StyledCard>
  );
}

export default memo(CardItem);
