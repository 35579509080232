import { SubmitHandler, useForm } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactButtonsContainer, StyledSection } from './styles';
import CustomInput from '../CustomInput';
import Button from '../Button';
import SectionHeading from '../SectionHeading';
import { messages } from './messages';
import { messages as globalMessages } from '../messages';

import SvgIcon from '../SvgIcon';
import { ThemeInterface } from '../Theme';

import contactImg from '../../images/contact-img.png';
import contactImgAr from '../../images/ar/contact-img.png';
import contactImg2 from '../../images/contact-img2.png';
import contactImg2Ar from '../../images/ar/contact-img2.png';
import useContact from '../../hooks/useContact';
import { GlobalContext } from '../../context';

const ContactMainContainer = styled.div`
  padding: 110px 112px 45px 112px;
  position: relative;
  margin-bottom: 120px;
  background: ${props =>
      props.theme.rtl
        ? `url(${contactImg2Ar}) no-repeat right bottom`
        : `url(${contactImg2}) no-repeat left bottom`},
    ${props =>
      props.theme.rtl
        ? `url(${contactImgAr}) no-repeat left 50%`
        : `url(${contactImg}) no-repeat right 50%`};
  background-size: 24.5% auto, 32.9% auto;
  @media only screen and (max-width: 1024px) {
    padding: 70px 40px 45px 40px;
    margin-bottom: 60px;
    background-size: 16% auto, 40% auto;
  }
  @media only screen and (max-width: 640px) {
    background-size: 16% auto, 0 auto;
  }
`;
const StyledContactContainer = styled.div`
  /* padding: 0 112px; */
  position: relative;
`;

export const StyledContactForm = styled.form`
  width: 100%;
  max-width: 42%;
  @media only screen and (max-width: 1024px) {
    width: 60%;
    max-width: none;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

const TextError = styled.p`
  color: ${props => props.theme.colors.danger};
  border-radius: 14px;
  padding: 8px 10px;
  font-weight: bold;
`;

const ControlGroup = styled.div`
  margin-bottom: 30px;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;

const submitIcon = (
  <svg
    version="1.2"
    baseProfile="tiny-ps"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 41 27"
    width="41"
    height="27"
  >
    <path d="M13.83 26.38L12.75 15.09L41.22 0.62L-0.22 12.26L9.87 16.5L13.83 26.38ZM41.22 0.62L17.8 19.68L13.83 26.38L21.4 21.09L29.33 24.62L41.22 0.62Z" />
  </svg>
);

export type IContactValues = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

function ContactSection({ id }: { id?: string }) {
  const { t } = useTranslation();

  const {
    state: { loading },
    dispatch,
  } = useContext(GlobalContext);

  const { contactRequest } = useContact(dispatch);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IContactValues>();

  const onSubmit: SubmitHandler<IContactValues> = async data => {
    console.log('submit', { data });
    await contactRequest(data);
  };

  const theme = useTheme();
  return (
    <StyledSection id={id}>
      <ContactMainContainer>
        <SectionHeading
          title={t(messages.contactSection.title())}
          subTitle={t(messages.contactSection.subTitle())}
          align="center"
          style={{ marginBottom: '50px' }}
        />
        <StyledContactContainer>
          <StyledContactForm onSubmit={handleSubmit(onSubmit)}>
            <ControlGroup>
              <CustomInput
                type="text"
                placeholder={t(messages.contactSection.form.name())}
                {...register('name', {
                  required: {
                    value: true,
                    message: t(globalMessages.errors.inputRequired()),
                  },
                })}
                hasError={!!errors.name}
              />
              {errors.name && <TextError>{errors.name.message}</TextError>}
            </ControlGroup>
            <ControlGroup>
              <CustomInput
                type="email"
                placeholder={t(messages.contactSection.form.email())}
                {...register('email', {
                  required: {
                    value: true,
                    message: t(globalMessages.errors.inputRequired()),
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t(globalMessages.errors.emailNotValid()),
                  },
                })}
                hasError={!!errors.email}
              />
              {errors.email && <TextError>{errors.email.message}</TextError>}
            </ControlGroup>
            <ControlGroup>
              <CustomInput
                type="text"
                placeholder={t(messages.contactSection.form.subject())}
                {...register('subject', {
                  required: {
                    value: true,
                    message: t(globalMessages.errors.inputRequired()),
                  },
                })}
                hasError={!!errors.subject}
              />
              {errors.subject && (
                <TextError>{errors.subject.message}</TextError>
              )}
            </ControlGroup>
            <ControlGroup>
              <CustomInput
                type="text-area"
                placeholder={t(messages.contactSection.form.content())}
                // rows={6}
                {...register('message', {
                  required: {
                    value: true,
                    message: t(globalMessages.errors.inputRequired()),
                  },
                })}
                hasError={!!errors.message}
              />
              {errors.message && (
                <TextError>{errors.message.message}</TextError>
              )}
            </ControlGroup>
            <ContactButtonsContainer>
              <Button
                title={t(messages.contactSection.cancelText())}
                ghost
                rounded
                htmlType="reset"
                onClick={() => {
                  reset();
                }}
              />
              <Button
                disabled={loading}
                primary
                htmlType="submit"
                icon={
                  <SvgIcon
                    notRtl
                    iconColor={(theme as ThemeInterface).colors.white}
                  >
                    {submitIcon}
                  </SvgIcon>
                }
                rounded
              />
            </ContactButtonsContainer>
          </StyledContactForm>
        </StyledContactContainer>
      </ContactMainContainer>
    </StyledSection>
  );
}

export default memo(ContactSection);
