import { memo, ReactNode } from 'react';
import styled from 'styled-components';
import Header from '../Header';
import Footer from '../Footer';

interface LayoutProps {
  children: ReactNode;
  isActive: string;
}

const Container = styled.main`
  background: ${props => props.theme.colors.mainBackground};
  transition: background-color 0.5s ease 0s;
`;

function Layout({ children, isActive }: LayoutProps) {
  return (
    <>
      <Header activeLink={isActive} fixed />
      <Container>{children}</Container>
      <Footer activeLink={isActive} />
    </>
  );
}

export default memo(Layout);
