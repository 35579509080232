import { Dispatch } from 'react';
import { http, HTTP_METHODS } from '../utils/http';
import { IContactValues } from '../components/Sections/ContactSection';
import { errorToast, loadingToast, successToast } from '../utils/toast';
import { toggleLoading } from '../context/actions';
import { Action } from '../context/types';

export default function useContact(dispatch: Dispatch<Action>) {
  async function contactRequest(data: IContactValues) {
    dispatch(toggleLoading());
    const id = loadingToast();
    try {
      await http('saveContact', HTTP_METHODS.POST, {
        body: data,
      });

      successToast(id, 'message envoyé !');
      dispatch(toggleLoading());
    } catch (error) {
      // @ts-ignore
      errorToast(id, error?.response?.message);
      dispatch(toggleLoading());
    }
  }

  return {
    contactRequest,
  };
}
