import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { StyledSection, SectionActionContainer } from './styles';
import SectionHeading from '../SectionHeading';
import { messages } from './messages';
import { messages as globalMessages } from '../messages';

import CardItem from '../Card';
import Button from '../Button';

import ImgStep01 from '../../images/step01.png';
import ImgStep02 from '../../images/step02.png';
import ImgStep03 from '../../images/step03.png';

import howitworksImg from '../../images/howitworks-img.png';
import howitworksImgAr from '../../images/ar/howitworks-img.png';
import howitworksImg2 from '../../images/howitworks-img2.png';
import howitworksImg2Ar from '../../images/ar/howitworks-img2.png';
import { SECTIONS_PATHS } from '../../utils/data';

const HowItWorksMainContainer = styled.div`
  padding: 290px 112px 120px 112px;
  position: relative;
  margin-bottom: 20px;
  background: ${props =>
      props.theme.rtl
        ? `url(${howitworksImg2Ar}) no-repeat right bottom`
        : `url(${howitworksImg2}) no-repeat left bottom`},
    ${props =>
      props.theme.rtl
        ? `url(${howitworksImgAr}) no-repeat left bottom`
        : `url(${howitworksImg}) no-repeat right bottom`};
  background-size: 10% auto, 42.8% auto;
  @media only screen and (max-width: 1024px) {
    padding: 90px 40px 50px 40px;
  }
  @media only screen and (max-width: 640px) {
    padding: 70px 40px 50px 40px;
    background-size: 0 auto, 100% auto;
  }
  /* &:after {
    content: url(${howitworksImg});
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
    max-width: 50%;
  }
  &:before {
    content: url(${howitworksImg2});
    position: absolute;
    left: 0;
    bottom: 60px;
    z-index: 0;
  } */
`;
const HowItWorksMainbody = styled.div`
  position: relative;
  z-index: 2;
`;
const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 750px;
  margin-bottom: 75px;
  @media only screen and (max-width: 1024px) {
    max-width: 700px;
    margin-bottom: 45px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 540px;
  }
  @media only screen and (max-width: 640px) {
    flex-direction: column;
    max-width: 50%;
  }
`;

function HowItWorksSection({ id }: { id?: string }) {
  const { t } = useTranslation();
  return (
    <StyledSection id={id}>
      <HowItWorksMainContainer>
        <HowItWorksMainbody>
          <SectionHeading
            title={t(messages.howItWorksSection.title())}
            subTitle={t(messages.howItWorksSection.subTitle())}
            align="flex-start"
            style={{ marginBottom: '35px' }}
          />
          <CardsContainer>
            <CardItem
              headImgSrc={ImgStep01}
              title={
                <h2 className="card-title">
                  {t(messages.howItWorksSection.infoCard.title())}
                </h2>
              }
              description={t(messages.howItWorksSection.infoCard.subTitle())}
            />
            <CardItem
              headImgSrc={ImgStep02}
              title={
                <h2 className="card-title">
                  {t(messages.howItWorksSection.paymentCard.title())}
                </h2>
              }
              description={t(messages.howItWorksSection.paymentCard.subTitle())}
            />
            <CardItem
              headImgSrc={ImgStep03}
              title={
                <h2 className="card-title">
                  {t(messages.howItWorksSection.downloadReceiptCard.title())}
                </h2>
              }
              description={t(
                messages.howItWorksSection.downloadReceiptCard.subTitle(),
              )}
            />
          </CardsContainer>
          <SectionActionContainer center>
            <Button
              title={`${t(globalMessages.navigationBar.paymentLinkTitle())}`}
              big
              htmlType="button"
              rounded
              onClick={async e => {
                e.preventDefault();
                await navigate(`#${SECTIONS_PATHS.PAYMENT}`);
              }}
            />
          </SectionActionContainer>
        </HowItWorksMainbody>
      </HowItWorksMainContainer>
    </StyledSection>
  );
}

export default memo(HowItWorksSection);
