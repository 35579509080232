import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { SECTIONS_PATHS, FAQS } from '../../utils/data';
import { SectionActionContainer, StyledSection } from './styles';
import SectionHeading from '../SectionHeading';
import Accordion from '../Accordion';
import { messages } from './messages';
import Button from '../Button';

const FaqMainContainer = styled.div`
  padding: 120px 100px 0 100px;
  margin-bottom: 20px;
  @media only screen and (max-width: 1024px) {
    padding: 70px 40px 0 40px;
  }
`;

function FaqSection({ id }: { id?: string }) {
  const { t } = useTranslation();
  return (
    <StyledSection id={id}>
      <FaqMainContainer>
        <SectionHeading
          title={t(messages.faqSection.title())}
          subTitle={t(messages.faqSection.subTitle())}
          align="center"
          style={{
            marginBottom: '40px',
          }}
        />
        <Accordion>
          {FAQS.slice(0, 3).map(({ title, description }, index) => (
            <Accordion.AccordionItem
              description={description}
              title={title}
              index={index.toString()}
              key={index.toString()}
            />
          ))}
        </Accordion>
        <SectionActionContainer center>
          <Button
            onClick={async e => {
              e.preventDefault();
              await navigate(`/${SECTIONS_PATHS.FAQ}`);
            }}
            title={t(messages.faqSection.actionLink())}
            primary
            big
            htmlType="button"
            rounded
          />
        </SectionActionContainer>
      </FaqMainContainer>
    </StyledSection>
  );
}

export default memo(FaqSection);
