import styled from 'styled-components';

export const StyledSection = styled.section``;

export const ContactButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SectionActionContainer = styled.div<{ center?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  position: relative;
`;
