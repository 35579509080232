import styled, { useTheme } from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { isEmpty } from '../../utils/helpers';
import CustomInput from '../CustomInput';
import Button, { StyledButton } from '../Button';
import Select from '../Select';
import {
  CAR_PLATES_LETTERS,
  FIELD_NAMES,
  SECTIONS_PATHS,
} from '../../utils/data';
import SvgIcon from '../SvgIcon';
import { ThemeInterface } from '../Theme';
import { messages } from '../messages';
import { resetSteps, setCarRegistrationValues } from '../../context/actions';
import { GlobalContext } from '../../context';
import Arrow from '../svgs/Arrow';

const StyledMultiInputForm = styled.form`
  display: flex;
  width: 470px;
  max-width: 100%;
  height: 70px;
  background: ${props => props.theme.colors.mainBackground};
  box-shadow: 0 0 20px 0 ${props => props.theme.colors.shadow};
  border-radius: 35px;
  padding: ${props => (props.theme.rtl ? '10px 30px 10px 60px' : '10px 30px')};
  position: relative;
  z-index: 3;
  direction: ltr;
  @media screen and (max-width: 640px) {
    height: 60px;
    width: 400px;
    padding: ${props =>
      props.theme.rtl ? '10px 30px 10px 60px' : '10px 20px'};
    ${StyledButton} {
      height: 60px;
      width: 60px;
    }
  }
`;

const StyledErrorMessage = styled.p`
  color: ${props => props.theme.colors.danger};
  margin-top: 20px;
  font-weight: bold;
`;

type ICarRegistrationInputFormValues = {
  carRegistrationChunk1: string;
  carRegistrationChunk2: string;
  carRegistrationChunk3: string;
};

function MultiInput() {
  const { t } = useTranslation();

  const { dispatch } = useContext(GlobalContext);

  const {
    control,
    formState: { errors },
    getValues,
  } = useForm<ICarRegistrationInputFormValues>({
    defaultValues: {
      [FIELD_NAMES.CAR_REGISTRATION_1]: '',
      [FIELD_NAMES.CAR_REGISTRATION_2]: CAR_PLATES_LETTERS[0].value,
      [FIELD_NAMES.CAR_REGISTRATION_3]: '',
    },
    mode: 'onChange',
  });

  // console.log('multi input', { errors });

  const theme = useTheme();

  const handleSubmit = useCallback(async () => {
    console.log('submiting....', errors);
    if (isEmpty(errors)) {
      const values = getValues();
      console.log('submitting .... ', values);
      dispatch(resetSteps());
      dispatch(setCarRegistrationValues(values));
      await navigate(`${SECTIONS_PATHS.PAYMENT}`);
    }
  }, [errors, getValues, dispatch]);

  return (
    <>
      <StyledMultiInputForm>
        <Controller
          // @ts-ignore
          name={FIELD_NAMES.CAR_REGISTRATION_1}
          control={control}
          rules={{
            validate: (v: string) => {
              console.log({ v });
              const val = v.replace(/\s/g, '');
              return (
                (val.length > 0 && val.length <= 8) ||
                (t(messages.errors.multiInput.carRegistration()) as string)
              );
            },
          }}
          render={({ field }) => (
            <CustomInput
              type="serial"
              {...field}
              limit={8}
              placeholder="## ### ###"
            />
          )}
        />
        <Controller
          // @ts-ignore
          name={FIELD_NAMES.CAR_REGISTRATION_2}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={CAR_PLATES_LETTERS}
              defaultValue={CAR_PLATES_LETTERS[0]}
              // isRtl={false}
              isRtl={(theme as ThemeInterface).rtl}
            />
          )}
        />
        <Controller
          // @ts-ignore
          name={FIELD_NAMES.CAR_REGISTRATION_3}
          control={control}
          render={({ field }) => (
            <CustomInput type="serial" {...field} limit={3} placeholder="###" />
          )}
          rules={{
            validate: (v: string) =>
              (v.length > 0 && v.length <= 3) ||
              (t(messages.errors.multiInput.carRegistration()) as string),
          }}
        />

        <Button
          htmlType="button"
          circular
          icon={
            <SvgIcon iconColor={(theme as ThemeInterface).colors.white}>
              <Arrow />
            </SvgIcon>
          }
          onClick={handleSubmit}
        />
      </StyledMultiInputForm>
      {(errors.carRegistrationChunk1 || errors.carRegistrationChunk3) && (
        <StyledErrorMessage>
          {errors?.carRegistrationChunk1?.message ||
            errors?.carRegistrationChunk3?.message}
        </StyledErrorMessage>
      )}
    </>
  );
}

export default memo(MultiInput);
