import styled from 'styled-components';

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import homeImg from '../../images/home-bg.png';

import { StyledSection } from './styles';
import SectionHeading from '../SectionHeading';
import MultiInput from '../MultiInput';
import { messages } from './messages';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 140px 50px 20px 50px;
  min-height: 724px;
  background: url(${homeImg}) no-repeat 50% 100%;
  margin-bottom: 35px;
  @media only screen and (max-width: 1024px) {
    background-size: contain;
    min-height: 500px;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 640px) {
    background-size: 160%;
    min-height: 570px;
    background-position: 0 100%;
    padding: 140px 30px 20px 30px;
  }
`;

function MainSection({ id }: { id?: string }) {
  const { t } = useTranslation();
  return (
    <StyledSection id={id}>
      <HomeContainer>
        <SectionHeading
          title={t(messages.mainSection.title())}
          align="center"
          description={t(messages.mainSection.description())}
          style={{
            marginBottom: '45px',
          }}
        />
        <MultiInput />
      </HomeContainer>
    </StyledSection>
  );
}

export default memo(MainSection);
